<template>
    <div class="card card-top-line">
        <div class="header d-flex align-items-center justify-content-between">
            <h2>Account Holder Information</h2>
            <i class="fa fa-edit font-20" @click="editDetails()"></i>
        </div>
        <div class="body">
            <div class="edit-form">
                <ValidationObserver v-slot="{ passes }">
                    <form @submit.prevent="passes(accountHolderSubmit)" id="personal-info" ref="form">
                        <div class="row">
                            <div class="col-sm-6 col-12">
                                <!-- <ValidationProvider name="title" rules="required" v-slot="{ errors}">
                                    <div class="form-group" :class="{'errors': errors.length && !accountHolder.title,'success':accountHolder.title}">
                                        <span class="float-label">
                                            <input type="text" v-model="accountHolder.title" class="form-control" id="title" placeholder="Title*" :disabled="accountHolder.readonlyMode">
                                            <label for="title" class="control-label">Title*</label>
                                            <div class="line"></div>
                                            <i class="fa fa-times" v-if="errors.length && !accountHolder.title && !accountHolder.readonlyMode"></i>
                                            <i class="fa fa-check" v-if="accountHolder.title && !accountHolder.readonlyMode"></i>
                                        </span>
                                    </div>
                                </ValidationProvider> -->
                                <ValidationProvider name="title" rules="required" v-slot="{ errors}">
                                    <div class="country-dropdown">
                                        <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':accountHolder.title && !errors[0]}">
                                            <span class="float-label">
                                            <label for="gender">Title*</label>
                                                <multiselect
                                                    :disabled="accountHolder.readonlyMode"
                                                    placeholder=""
                                                    v-model="accountHolder.title"
                                                    :show-labels="false" 
                                                    :options="title_options" 
                                                    :searchable="false">
                                                </multiselect>  
                                                <i class="fa fa-times" v-if="errors.length && errors[0] && !accountHolder.readonlyMode"></i>
                                                <i class="fa fa-check" v-if="accountHolder.title && !errors[0] && !accountHolder.readonlyMode"></i>
                                            </span>
                                        </div>
                                    </div>
                                </ValidationProvider>
                                
                                <ValidationProvider name="middleName" v-slot="{ errors}">
                                    <div class="form-group" :class="{'errors': errors.length && !accountHolder.middleName || errorArr[2] && errorArr[2].fieldName!='','success':accountHolder.middleName && errorArr[2] && errorArr[2].fieldName==''}">
                                        <span class="float-label">
                                            <input type="text" v-model="accountHolder.middleName" class="form-control" id="middleName" placeholder="Middle Name*" :disabled="accountHolder.readonlyMode" @input="checkNameFormat($event, 'middleName', 'Middle Name',2)">
                                            <label for="middleName" class="control-label">Middle Name</label>
                                            <div class="line"></div>
                                            <i class="fa fa-times" v-if="errors.length && !accountHolder.middleName && !accountHolder.readonlyMode || errorArr[2] && errorArr[2].fieldName!=''"></i>
                                            <i class="fa fa-check" v-if="accountHolder.middleName && !accountHolder.readonlyMode && errorArr[2] && errorArr[2].fieldName==''"></i>
                                        </span>
                                        <span class="text-danger error-msg" v-if="errorArr[2] && errorArr[2].fieldName!=''">{{errorArr[2].errorMessage}}</span>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="gender" rules="required" v-slot="{ errors}"  v-if="!accountHolder.readonlyMode">
                                    <div class="country-dropdown">
                                        <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':accountHolder.gender && !errors[0]}">
                                            <span class="float-label">
                                            <label for="gender">Gender*</label>
                                                <multiselect
                                                    :disabled="accountHolder.readonlyMode"
                                                    placeholder=""
                                                    v-model="accountHolder.gender"
                                                    :show-labels="false" 
                                                    :options="gender_options" 
                                                    :searchable="false">
                                                </multiselect>  
                                                <i class="fa fa-times" v-if="errors.length && errors[0] && !accountHolder.readonlyMode"></i>
                                                <i class="fa fa-check" v-if="accountHolder.gender && !errors[0] && !accountHolder.readonlyMode"></i>
                                            </span>
                                        </div>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="birthdate" rules="required" v-slot="{ errors }" v-if="!accountHolder.readonlyMode">
                                    <div class="form-group datepicker" :class="{'errors': errors[0],'success':accountHolder.birthdate && !errors[0]}">
                                        <span class="float-label">
                                            <b-input-group :class="{'datepicker-disable':accountHolder.readonlyMode}">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">DOB</span>
                                                    <span class="input-group-text" @click="$refs.birthdate.showCalendar()"><i class="icon-calendar"></i></span>
                                                </div>
                                                <datepicker class="form-control" :open-date="accountHolder.defaultDateSet" :disabled-dates="dateOfBirthDisabled.disabledDates" ref="birthdate" :bootstrap-styling="false" placeholder="" v-model="accountHolder.birthdate" :disabled="accountHolder.readonlyMode" :typeable="true"></datepicker>
                                            </b-input-group>
                                            <i class="fa fa-times" v-if="errors.length && errors[0] && !accountHolder.readonlyMode"></i>
                                            <i class="fa fa-check" v-if="accountHolder.birthdate && !errors[0] && !accountHolder.readonlyMode"></i>
                                        </span>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="cob" rules="required" v-slot="{ errors}" v-if="!accountHolder.readonlyMode">
                                    <div class="country-dropdown">
                                        <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':accountHolder.countryofBirth && !errors[0]}">
                                            <span class="float-label" :class="{'multiselect--disabled':accountHolder.readonlyMode}">
                                            <label for="Country">Country of Birth*</label>
                                                <multiselect
                                                    :disabled="accountHolder.readonlyMode"
                                                    placeholder=""
                                                    v-model="accountHolder.countryofBirth" 
                                                    :show-labels="false" 
                                                    :options="countryofBirth_options" 
                                                    :searchable="false"
                                                    label="name" 
                                                    track-by="name">
                                                    <template slot="option" slot-scope="props">
                                                        <span :class="props.option.flagClass"></span>
                                                        <div class="option__desc">
                                                            <span class="option__title">{{ props.option.name }}</span>
                                                        </div>
                                                    </template>
                                                </multiselect>  
                                                <i class="fa fa-times" v-if="errors.length && errors[0] && !accountHolder.readonlyMode"></i>
                                                <i class="fa fa-check" v-if="accountHolder.countryofBirth && !errors[0] && !accountHolder.readonlyMode"></i>
                                            </span>
                                        </div>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="email-address" rules="required|email" v-slot="{ errors}">
                                    <div class="form-group" :class="{'errors': errors.length && !accountHolder.email || errors[0],'success':accountHolder.email && !errors[0]}">
                                        <span class="float-label">
                                            <input type="email" v-model="accountHolder.email" class="form-control" name="email" id="email-address" placeholder="Email Address*" readonly>
                                            <label for="email-address" class="control-label">Email Address*</label>
                                            <div class="line"></div>
                                            <i class="fa fa-times" v-if="errors.length && !accountHolder.email || errors[0] && !accountHolder.readonlyMode"></i>
                                            <i class="fa fa-check" v-if="accountHolder.email && !errors[0] && !accountHolder.readonlyMode"></i>
                                        </span>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="phoneNumber" rules="required"  v-if="!accountHolder.readonlyMode">
                                    <div class="form-group" :class="{'errors': mobileInputError == false,'success':accountHolder.phoneNumber && mobileInputError}">
                                        <span class="float-label">
                                            <label for="phoneNumber" class="control-label">Mobile Number*</label>
                                            <vue-tel-input
                                                v-model="accountHolder.phoneNumber"
                                                v-bind="primaryMobileNumber"
                                                @validate ="mobileInputValidate"
                                                ref="telInput"
                                                @keypress.native="onlyNumber($event)">
                                                <template v-slot:arrow-icon>
                                                    <span class="vti__dropdown-arrow">{{ '▼' }}</span>
                                                </template>
                                             </vue-tel-input>
                                            <div class="line"></div>
                                            <i class="fa fa-times" v-if="mobileInputError == false && !accountHolder.readonlyMode"></i>
                                            <i class="fa fa-check" v-if="mobileInputError  && !accountHolder.readonlyMode"></i>
                                        </span>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="role" rules="required" v-slot="{ errors}"  v-if="!accountHolder.readonlyMode">
                                    <div class="country-dropdown">
                                        <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':accountHolder.role && !errors[0]}">
                                            <span class="float-label">
                                            <label for="Country">Your Role*</label>
                                                <multiselect
                                                    :disabled="accountHolder.readonlyMode"
                                                    placeholder=""
                                                    v-model="accountHolder.role"
                                                    :show-labels="false" 
                                                    label="name"
                                                    :options="role_options" 
                                                    :searchable="false">
                                                </multiselect>  
                                                <i class="fa fa-times" v-if="errors.length && errors[0] && !accountHolder.readonlyMode"></i>
                                                <i class="fa fa-check" v-if="accountHolder.role && !errors[0] && !accountHolder.readonlyMode"></i>
                                            </span>
                                        </div>
                                    </div>
                                </ValidationProvider>
                                
                            </div>
                            <div class="col-sm-6 col-12">
                                <ValidationProvider name="firstName" rules="required" v-slot="{ errors}">
                                    <div class="form-group" :class="{'errors': errors.length && !accountHolder.firstName || errorArr[0] && errorArr[0].fieldName!='','success':accountHolder.firstName && errorArr[0] && errorArr[0].fieldName==''}">
                                        <span class="float-label">
                                            <input type="text" v-model="accountHolder.firstName" class="form-control" id="firstName" placeholder="First Name*" :disabled="accountHolder.readonlyMode" @input="checkNameFormat($event, 'firstName', 'First Name',0)">
                                            <label for="firstName" class="control-label">First Name*</label>
                                            <div class="line"></div>
                                            <i class="fa fa-times" v-if="errors.length && !accountHolder.firstName && !accountHolder.readonlyMode || errorArr[0] && errorArr[0].fieldName!=''"></i>
                                            <i class="fa fa-check" v-if="accountHolder.firstName && !accountHolder.readonlyMode && errorArr[0] && errorArr[0].fieldName==''"></i>
                                        </span>
                                        <span class="text-danger error-msg" v-if="errorArr[0] && errorArr[0].fieldName!=''">{{errorArr[0].errorMessage}}</span>

                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="lastName" rules="required" v-slot="{ errors}">
                                    <div class="form-group" :class="{'errors': errors.length && !accountHolder.lastName || errorArr[1] && errorArr[1].fieldName!='','success':accountHolder.lastName && errorArr[1] && errorArr[1].fieldName==''}">
                                        <span class="float-label">
                                            <input type="text" v-model="accountHolder.lastName" class="form-control" id="lastName" placeholder="Last Name*" :disabled="accountHolder.readonlyMode" @input="checkNameFormat($event, 'lastName', 'Last Name',1)">
                                            <label for="lastName" class="control-label">Last Name*</label>
                                            <div class="line"></div>
                                            <i class="fa fa-times" v-if="errors.length && !accountHolder.lastName && !accountHolder.readonlyMode || errorArr[1] && errorArr[1].fieldName!=''"></i>
                                            <i class="fa fa-check" v-if="accountHolder.lastName && !accountHolder.readonlyMode && errorArr[1] && errorArr[1].fieldName==''"></i>
                                        </span>
                                        <span class="text-danger error-msg" v-if="errorArr[1] && errorArr[1].fieldName!=''">{{errorArr[1].errorMessage}}</span>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="cor" rules="required" v-slot="{ errors}">
                                    <div class="country-dropdown">
                                        <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':accountHolder.countryofResidence && !errors[0]}">
                                            <span class="float-label" :class="{'multiselect--disabled':accountHolder.readonlyMode}">
                                            <label for="Country">Country of Residence*</label>
                                                <multiselect
                                                    :disabled="accountHolder.readonlyMode"
                                                    placeholder=""
                                                    v-model="accountHolder.countryofResidence"
                                                    :show-labels="false" 
                                                    :options="countryofResidence_options" 
                                                    :searchable="false"
                                                    label="name" 
                                                    track-by="name"
                                                    @select="setPrimaryNumberCountryCode">
                                                    <template slot="option" slot-scope="props">
                                                        <span :class="props.option.flagClass"></span>
                                                        <div class="option__desc">
                                                            <span class="option__title">{{ props.option.name }}</span>
                                                        </div>
                                                    </template>
                                                </multiselect>  
                                                <i class="fa fa-times" v-if="errors.length && errors[0] && !accountHolder.readonlyMode"></i>
                                                <i class="fa fa-check" v-if="accountHolder.countryofResidence && !errors[0] && !accountHolder.readonlyMode"></i>
                                            </span>
                                        </div>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="address1" rules="required" v-slot="{ errors}" v-if="!accountHolder.readonlyMode">
                                    <div class="form-group address-picker" :class="{'errors': errors.length && !accountHolder.address1,'success':accountHolder.address1}">
                                        <span class="float-label">
                                            <input type="text" v-if="queryAddress==true" v-model="accountHolder.address1" class="form-control" id="address1" placeholder="Address Line 1*" :disabled="accountHolder.readonlyMode" v-on:keyup="loqateAddress($event,'address1')">
                                            <input type="text" v-if="queryAddress==false" v-model="accountHolder.address1" class="form-control" id="address1" placeholder="Address Line 1*" :disabled="accountHolder.readonlyMode">
                                            <label for="address1" class="control-label">Address Line 1*</label>
                                            <div class="line"></div>
                                            <i class="fa fa-times" v-if="errors.length && !accountHolder.address1 && !accountHolder.readonlyMode"></i>
                                            <i class="fa fa-check" v-if="accountHolder.address1 && !accountHolder.readonlyMode"></i>
                                        </span>
                                        <span class="text-danger error-msg" v-if="fieldName == 'address1'">{{fieldErrorMsg}}</span>
                                        <ul v-if="addressData!='' && fieldType=='address1' && queryAddress==true">
                                            <li class="" v-for="item in addressData" :key="item.Id" @click="SelectAddress(item.Id, item.Type)">{{ item.Text }}, {{ item.Description }}
                                                <i class="fa fa-angle-right" v-if="item.Type!='Address'"></i>
                                            </li>    
                                        </ul>
                                    </div>
                                </ValidationProvider>
                                <div class="form-group address-picker" v-if="!accountHolder.readonlyMode">
                                    <span class="float-label">
                                        <input type="text" v-model="accountHolder.address2" class="form-control" id="address2" placeholder="Address Line 2" :disabled="accountHolder.readonlyMode" v-on:keyup="loqateAddress($event,'address2')">
                                        <label for="address2" class="control-label">Address Line 2</label>
                                        <div class="line"></div>
                                    </span>
                                    <span class="text-danger error-msg" v-if="fieldName == 'address2'">{{fieldErrorMsg}}</span>
                                    <ul v-if="addressData!='' && fieldType=='address2'">
                                        <li class="" v-for="item in addressData" :key="item.Id" @click="SelectAddress(item.Id, item.Type)">{{ item.Text }}, {{ item.Description }}
                                        <i class="fa fa-angle-right" v-if="item.Type!='Address'"></i>
                                        </li>    
                                    </ul>                                
                                </div>
                                <ValidationProvider name="taxId" rules="required" v-slot="{ errors }" v-if="!accountHolder.readonlyMode && showTaxField==true">
                                    <div class="form-group address-picker" :class="{'errors': errors[0],'success':accountHolder.taxId && !errors[0]}">
                                        <span class="float-label">
                                            <!-- <input type="text" v-if="queryTown==true" class="form-control" id="town" v-model="accountHolder.town" placeholder="Town*" :disabled="accountHolder.readonlyMode" v-on:keyup="loqateAddress($event,'town')"> -->
                                            <input type="text"  class="form-control" id="taxId" v-model="accountHolder.taxId" placeholder="TaxId Number*" :disabled="accountHolder.readonlyMode">
                                            <label for="town" class="control-label">Tax Id Number*</label>
                                            <div class="line"></div>
                                            <i class="fa fa-times" v-if="errors.length && errors[0] && !accountHolder.readonlyMode"></i>
                                            <i class="fa fa-check" v-if="accountHolder.taxId && !errors[0] && !accountHolder.readonlyMode"></i>
                                        </span>
                                                                            
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="city" rules="required" v-slot="{ errors}" v-if="!accountHolder.readonlyMode">
                                    <div class="form-group address-picker" :class="{'errors': errors.length && !accountHolder.city,'success':accountHolder.city}">
                                        <span class="float-label">
                                            <input type="text" v-if="queryCity==true" v-model="accountHolder.city" class="form-control" id="city" placeholder="City*" :disabled="accountHolder.readonlyMode" v-on:keyup="loqateAddress($event,'city')">
                                            <input type="text" v-if="queryCity==false" v-model="accountHolder.city" class="form-control" id="city" placeholder="City*" :disabled="accountHolder.readonlyMode">
                                            <label for="city" class="control-label">City*</label>
                                            <div class="line"></div>
                                            <i class="fa fa-times" v-if="errors.length && !accountHolder.city && !accountHolder.readonlyMode"></i>
                                            <i class="fa fa-check" v-if="accountHolder.city && !accountHolder.readonlyMode"></i>
                                        </span>
                                        <ul v-if="addressData!='' && fieldType=='city' && queryCity==true">
                                            <li class="" v-for="item in addressData" :key="item.Id" @click="SelectAddress(item.Id, item.Type)">{{ item.Text }}, {{ item.Description }}
                                                <i class="fa fa-angle-right" v-if="item.Type!='Address'"></i>
                                            </li>    
                                        </ul>                                    
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="state" rules="required" v-slot="{ errors}" v-if="!accountHolder.readonlyMode">
                                    <div class="form-group address-picker" :class="{'errors': errors.length && !accountHolder.state,'success':accountHolder.state}">
                                        <span class="float-label">
                                            <input type="text" v-if="queryProvince==true" v-model="accountHolder.state" class="form-control" id="state" placeholder="State/Province/Region/County*" :disabled="accountHolder.readonlyMode" v-on:keyup="loqateAddress($event,'state')">
                                            <input type="text" v-if="queryProvince==false" v-model="accountHolder.state" class="form-control" id="state" placeholder="State/Province/Region/County*" :disabled="accountHolder.readonlyMode">
                                            <label for="state" class="control-label">State/Province/Region/County*</label>
                                            <div class="line"></div>
                                            <i class="fa fa-times" v-if="errors.length && !accountHolder.state && !accountHolder.readonlyMode"></i>
                                            <i class="fa fa-check" v-if="accountHolder.state && !accountHolder.readonlyMode"></i>
                                        </span>
                                        <ul v-if="addressData!='' && fieldType=='state' && queryProvince==true">
                                            <li class="" v-for="item in addressData" :key="item.Id" @click="SelectAddress(item.Id, item.Type)">{{ item.Text }}, {{ item.Description }}
                                                <i class="fa fa-angle-right" v-if="item.Type!='Address'"></i>
                                            </li>    
                                        </ul>                                    
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="zipCode" rules="required" v-slot="{ errors}" v-if="!accountHolder.readonlyMode">
                                    <div class="form-group address-picker" :class="{'errors': errors.length && !accountHolder.zipCode,'success':accountHolder.zipCode}">
                                        <span class="float-label">
                                            <input type="text" v-if="queryZip==true" v-model="accountHolder.zipCode" class="form-control" id="zipCode" placeholder="Post/Zip Code*" :disabled="accountHolder.readonlyMode" v-on:keyup="loqateAddress($event,'zipCode')">
                                            <input type="text" v-if="queryZip==false" v-model="accountHolder.zipCode" class="form-control" id="zipCode" placeholder="Post/Zip Code*" :disabled="accountHolder.readonlyMode">
                                            <label for="zipCode" class="control-label">Post/Zip Code*</label>
                                            <div class="line"></div>
                                            <i class="fa fa-times" v-if="errors.length && !accountHolder.zipCode && !accountHolder.readonlyMode"></i>
                                            <i class="fa fa-check" v-if="accountHolder.zipCode && !accountHolder.readonlyMode"></i>
                                        </span>
                                        <ul v-if="addressData!='' && fieldType=='zipCode' && queryZip==true">
                                            <li class="" v-for="item in addressData" :key="item.Id" @click="SelectAddress(item.Id, item.Type)">{{ item.Text }}, {{ item.Description }}
                                                <i class="fa fa-angle-right" v-if="item.Type!='Address'"></i>
                                            </li>    
                                        </ul>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div v-if="!accountHolder.readonlyMode" class="justify-content-center d-flex">
                            <b-button size="lg" @click="accountHolderSubmit()" class="btn-mw">Cancel</b-button>
                            <b-button type="submit" variant="primary ml-1 btn-mw" size="lg">Update</b-button>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>
<script>
const Datepicker = require('vuejs-datepicker').default
const { ValidationObserver,ValidationProvider} = require('vee-validate')
const Multiselect = require('vue-multiselect').default
const { VueTelInput } = require('vue-tel-input')
import "@/plugins/vee-validate";
const moment = require('moment')
var eighteenYearFromNow = new Date();
eighteenYearFromNow.setFullYear(eighteenYearFromNow.getFullYear() - 18);
var eighteenYearPreviousDate = moment(eighteenYearFromNow).format("YYYY,MM,DD");
import parsePhoneNumber from 'libphonenumber-js';
import shared from "@/shared.js";

export default {
    name: 'AccountHolderInfo-Profile',
    components:{
        Datepicker,
        ValidationObserver,
        ValidationProvider,
        Multiselect,
        VueTelInput
    },
    data(){
        return{
            //Account Holder Information
            accountHolder:{
                readonlyMode : true,
                title:null,
                firstName:'',
                middleName:'',
                lastName:'',
                birthdate:'',
                gender:'',
                countryofResidence:null,
                address1:'',
                address2:'',
                address3:'',
                address4:'',
                address5:'',
                street: '',
                buildingNumber: '',
                subBuilding: '',
                buildingName: '',
                taxId:null,
                city:'',
                state:'',
                zipCode:'',
                role:'',
                phoneNumber:'',
                email:'',
                countryofBirth:null,
                defaultDateSet:new Date(eighteenYearPreviousDate),
                primary_iso_code: ''
            },
            apiErrorCode:'',
            apiErrorMessage:'',
            queryAddress:true,
            queryTown:true,
            queryCity:true,
            queryProvince:true,
            showTaxField: false,
            queryZip:true,
            fieldName:'',
            fieldErrorMsg:'',
            addressData:[],
            fieldType:'',
            mobileInputError:'',
            dateOfBirthDisabled : {
                disabledDates: {
                    to: new Date(1850,12,29), // Disable all dates up to specific date
                    from: new Date(eighteenYearPreviousDate), // Disable all dates after specific date
                }
            },
            countryofResidence_options: [],
            countryofBirth_options: [],
            gender_options:['Male','Female'],
            title_options: ['Mr.', 'Mrs.', 'Miss', 'Dr.', 'Ms.', 'Prof.', 'Rev.', 'Lady', 'Sir', 'Capt.', 'Major', 'Lt.-Col.','Col.', 'Lt.-Cmdr.', 'Hon.', 'Cmdr.', 'Flt. Lt.', 'Brgdr.', 'Judge', 'Lord'],
            role_options: [],
            primaryMobileNumber:{},
            errorArr : [],

        }
    },methods:{
        async getBusinessUserProfileInfo(businessId) {
            //const response = await this.$http.get(this.$baseurl+"business/profile/"+businessId);
            const response = await this.$http.get(this.$baseurl+'business/profile/account-holder/'+businessId,{
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken") //`Basic ${token}`
                        },
                    });
            if(response.data.code == '0008-000') {
                const phoneNumber = parsePhoneNumber(response.data.details.phonenumber, response.data.details.countryofresidence.isocode);
                this.accountHolder.title                = response.data.details.title;
                this.accountHolder.firstName            = response.data.details.firstname;
                this.accountHolder.middleName           = response.data.details.middlename;
                this.accountHolder.lastName             = response.data.details.lastname;
                this.accountHolder.gender               = response.data.details.gender;
                this.accountHolder.role                 = {id: response.data.details.role.id, name: response.data.details.role.name};
                this.accountHolder.birthdate            = response.data.details.dob_day+'-'+response.data.details.dob_month+'-'+response.data.details.dob_year;
                this.accountHolder.zipCode              = response.data.details.postal;
                //this.accountHolder.town                 = response.data.details.town;
                this.accountHolder.city                 = response.data.details.city;
                this.accountHolder.state                = response.data.details.state;
                this.accountHolder.address1             = response.data.details.addressline1;
                this.accountHolder.address2             = response.data.details.addressline2;
                this.accountHolder.email                = response.data.details.email;
                this.accountHolder.phoneNumber          = phoneNumber.nationalNumber;
                this.accountHolder.countryofResidence   = {id: response.data.details.countryofresidence.countryid, name:response.data.details.countryofresidence.name, isocode: response.data.details.countryofresidence.isocode};
                this.accountHolder.countryofBirth       = {id: response.data.details.countryofbirth.countryid, name:response.data.details.countryofbirth.name, isocode: response.data.details.countryofbirth.isocode};
                this.accountHolder.primary_iso_code     = response.data.details.countryofresidence.isocode;
                this.primaryMobileNumber = {
                        mode: "auto", 
                        defaultCountry: response.data.details.countryofresidence.isocode,
                        autoFormat:false,
                        dropdownOptions:{
                            showFlags: true,
                            disabled:true,
                            showDialCodeInSelection:true
                        },
                    };
                    if(response.data.details.countryofresidence.isocode==='IT' || response.data.details.countryofresidence.isocode==='ES') {
                        this.showTaxField = true;
                    }
            } else {
                shared.toastrError(response.data.message);
            }
            
        },
        async getAvailableCountries() {
            try {
                const response = await this.$http.get(this.$baseurl+"countries/available");
                // JSON responses are automatically parsed.
                if(response.data.code == '0003-000') {
                    response.data.details.map((item) => {
                            let countryData = {
                                id:item.countryid, 
                                isocode: item.isocode, 
                                code: item.mobilecountrycode, 
                                name: item.countryname, 
                                flagClass:"flag-icon flag-icon-"+item.isocode.toLowerCase()+" fi-xl fi-round",
                                isChecked:false
                            }
                        this.countryofResidence_options.push(countryData);
                    })
                } else {
                    shared.toastrError(response.data.message);
                }
            } catch (error) {
                shared.toastrError(error);
                console.log(error);
            }
        },
        async getCountryofBirth() {
            try {
                const response = await this.$http.get(this.$baseurl+"countries");
                //JSON responses are automatically parsed.
                response.data.map((item) => {
                        let countryData = {
                            id:item.id, 
                            isocode: item.isoCode, 
                            code: item.dialCode, 
                            name: item.name, 
                            flagClass:"flag-icon flag-icon-"+item.isoCode.toLowerCase()+" fi-xl fi-round",
                            isChecked:false
                        }
                    this.countryofBirth_options.push(countryData);
                })
            } catch (error) {
                console.log(error);
                shared.toastrError(error);
            }
        },
        
        async getRolesData() {
            try {
                const response = await this.$http.get(this.$baseurl+'business/roles',{
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken") //`Basic ${token}`
                        },
                    });                
                // JSON responses are automatically parsed.
                if(response.data.code == '0097-000') {
                    response.data.details.map((item) => {
                            let roleOptionData = {
                                id:item.roleid, 
                                name: item.name, 
                            }
                        this.role_options.push(roleOptionData);
                    })
                } else {
                    shared.toastrError(response.data.message);
                }
            } catch (error) {
                shared.toastrError(error);
                console.log(error);
            }
        },
        loqateAddress: function($event,fieldType){
            let searchValue = $event.target.value;
            this.searchText = searchValue;
            this.fieldType = fieldType;
            var that = this;
           // setTimeout(function() {
                if(searchValue == ''){
                    that.addressData = '';
                } else {
                    let findAddressData = {
                        address : searchValue,
                        country : that.accountHolder.primary_iso_code,
                        type : "Address",
                        container_id : ""
                    }
                    // console.log(findAddressData);
                     //const response = await this.$http.get(this.$baseurl+'business/roles',{
                    that.$http.post(that.$apiV2+'find-address/', findAddressData).then((res) => {
                        console.log(res);
                        if(res.data.code == '0021-000'){
                            that.addressData = res.data.Items;
                        } else {
                            that.addressData = '';
                        }
                    }).catch((error) => {
                        console.log(error);
                        that.addressData = '';
                        // this.apiErrorCode = error.response.data.code;
                    })
                }
            //}, 2000);
        },
        SelectAddress(id,type){
            // console.log(item.Id, item.Type, this.searchText);
            // console.log(id, type);
            var that = this;
            if(type != 'Address') {
              //  setTimeout(function() {
                    let findAddressData = {
                        address : that.searchText,
                        country : that.accountHolder.primary_iso_code,
                        type : type,
                        container_id : id
                    }
                    // console.log(findAddressData);
                    that.$http.post(that.$apiV2+'/find-address/', findAddressData).then((res) => {
                        if(res.data.code == '0021-000'){
                            that.addressData = res.data.Items;
                        } else {
                            that.addressData = '';
                        }
                    }).catch((error) => {
                        console.log(error);
                        that.addressData = '';
                        // this.apiErrorCode = error.response.data.code;
                    })
               // }, 2000);
            } else {
                let retrieveAddressData = {
                    id : id
                }
                that.$http.post(that.$apiV2+'/retrieve-address/', retrieveAddressData).then((res) => {
                    if(res.data.code == '0020-000'){
                        let selectedAddress = res.data.Items[0];
                        // let line2 = '';
                        // if(selectedAddress.Line2 != ''){
                        //     line2 = selectedAddress.Line2 +', ';
                        // }
                        that.accountHolder.address1 = selectedAddress.Line1;
                        that.accountHolder.address2 = selectedAddress.Line2;
                        that.accountHolder.address3 = selectedAddress.Line3;
                        that.accountHolder.address4 = selectedAddress.Line4;
                        that.accountHolder.address5 = selectedAddress.Line5;
                        that.accountHolder.street   = selectedAddress.Street;
                        that.accountHolder.buildingNumber = selectedAddress.BuildingNumber;
                        that.accountHolder.subBuilding = selectedAddress.SubBuilding;
                        that.accountHolder.buildingName = selectedAddress.BuildingName;
                        that.accountHolder.city = selectedAddress.City;
                        that.accountHolder.zipCode = selectedAddress.PostalCode;
                        that.accountHolder.state = selectedAddress.Province;
                        if(selectedAddress.Line1 === '') {
                            this.queryAddress = false;
                        }
                        if(selectedAddress.City === '') {
                            this.queryCity = false;
                        }
                        if(selectedAddress.PostalCode === '') {
                            this.queryZip = false;
                        }
                        if(selectedAddress.Province === '') {
                            this.queryProvince = false;
                        }
                        
                        // that.accountDetails.address = selectedAddress.Line1 +', '+ line2 + selectedAddress.City +', '+ selectedAddress.PostalCode +', '+ selectedAddress.CountryName; // selectedAddress.Label.split("\n").join(", ");
                        // that.accountDetails.city = selectedAddress.City;
                        // that.accountDetails.postCode = selectedAddress.PostalCode;
                        that.searchText = '';
                        that.fieldType = '';
                        that.addressData = '';
                    } 
                    // else {
                    //     that.addressData = '';
                    // }
                }).catch((error) => {
                    console.log(error);
                    // this.apiErrorCode = error.response.data.code;
                })
            }
        },
        editDetails(){
            this.accountHolder.readonlyMode = false
        },
        mobileInputValidate(phoneObject){
            this.mobileInputError = phoneObject.valid
        },
        onlyNumber (event) {
            let keyCode = (event.keyCode ? event.keyCode : event.which);
            if (keyCode < 48 || keyCode > 57) { 
                event.preventDefault();
            }
            if (/^\W$/.test(event.key)) {
                event.preventDefault();
            }
        },
        setPrimaryNumberCountryCode(defaultCountry){
            this.accountHolder.primary_iso_code = defaultCountry.isocode;
            this.$refs.telInput.choose(defaultCountry.isocode);
            this.accountHolder.address1 = '';
            this.accountHolder.address2 = '';
            this.accountHolder.city = '';
            this.accountHolder.taxId = '';
            this.accountHolder.state = '';
            this.accountHolder.zipCode = '';
            // if(defaultCountry.isocode=='GH') {
            //      this.queryAddress = true;
            //      this.showTaxField = true;
            // } else {
            //     this.queryAddress = true;
            //     this.showTaxField = false;
            // }
            if(defaultCountry.isocode=='IT' || defaultCountry.isocode=='ES') {
                 this.queryAddress = true;
                 this.showTaxField = true;
            } else {
                this.queryAddress = true;
                this.showTaxField = false;
            }            
        },
        checkNameFormat(e, fieldName, elementName,  elementIndex) {
            // if(form==='single') {
            //     this.formType = this.singleBusinessDatas;
            // } else {
            //    this.formType = this.subBisnessData; 
            // }
            let char = e.target.value; //String.fromCharCode(e.keyCode); // Get the character
            let charLength = e.target.value.length;
            if(/^[A-Za-z'-\s]+$/.test(char)) {
                var splitStr = e.target.value.split(' ');
                for (var i = 0; i < splitStr.length; i++) {
                    if(splitStr[0]!='')
                    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
                }
            this.accountHolder[fieldName] = splitStr.join(' ');
                if(charLength >= 2 && charLength <= 35 && splitStr[0]!='') {
                    this.fieldErrorMsg = '';
                    this.fieldName = '';
                    if(this.errorArr.some(obj => obj.index == elementIndex)) {
                        this.errorArr[elementIndex].fieldName = '';
                        this.errorArr[elementIndex].errorMessage = '';
                        this.errorArr[elementIndex].index = elementIndex;
                        //this.errorArr.push({'fieldName': '', errorMessage:'', 'index': elementIndex})
                    }                   
                    return true; // Match with regex 

                } else {
                    if(this.errorArr.some(obj => obj.index == elementIndex)) {
                        this.errorArr[elementIndex].fieldName = fieldName;
                        this.errorArr[elementIndex].errorMessage = 'Please provide minimum two characters without spaces at start.';
                        this.errorArr[elementIndex].index = elementIndex;
                        //this.errorArr.push({'fieldName': '', errorMessage:'', 'index': elementIndex})
                    }                   
                }
            } else {
                this.accountHolder[fieldName] = char;
                this.fieldErrorMsg = 'Please provide valid value for '+elementName;
                if(!this.errorArr.some(obj => obj.index == elementIndex)) {
                    for(let i=0; i<elementIndex; i++) {
                        if(this.errorArr[i] === undefined){
                            this.errorArr.push({'fieldName': '', errorMessage:'', 'index': i})                        
                        }
                        //if(this.errorArr[elementIndex].fieldName == '')
                    }
                    this.errorArr.push({'fieldName': fieldName, errorMessage:this.fieldErrorMsg, 'index': elementIndex})
                } else if(this.errorArr.some(obj => obj.index == elementIndex)) {
                    this.errorArr[elementIndex].fieldName =  fieldName;
                    this.errorArr[elementIndex].errorMessage = this.fieldErrorMsg;
                    this.errorArr[elementIndex].index = elementIndex;
                } else {
                    // do nothing 
                }
               
                e.preventDefault(); // If not match, don't add to input text
            }
        },        
        //submit Personal Details information
        accountHolderSubmit(){
            console.log(this.accountHolder.taxId);
            // Object.values(this.accountHolder).filter(user => {
            //     console.log(user);
            // });
            this.accountHolder.readonlyMode  = true
        },
    },
    watch: {
        mobileNumber(val) {
            this.accountHolder.phoneNumber = val.replace(/\W/g, "");
        },
    },    
    mounted() {
       this.getBusinessUserProfileInfo(sessionStorage.getItem('businessId'));
       this.getAvailableCountries();
       this.getCountryofBirth();
       this.getRolesData();
    }
}
</script>